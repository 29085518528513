import { Link } from 'react-router-dom';
import './Cart.css';
import { useState } from 'react';

export default function Cart({ cart, handlePlusToCart, handleMinusFromCart, placeOrder, setCart }) {

    const [isOrderAdded, setisOrderAdded] = useState(false);

    const products = (
        cart.map(item => {
            return ({
                "uid": item.metadata.uid,
                "amount": item.quantity
            })
        })
    );

    function handleSubmitOrder(e) {
        e.preventDefault();
        const formData = new FormData(e.target);
        const customer = {
            "fullname": formData.get('fullname'),
            "phone": formData.get('phone'),
            "email": formData.get('email'),
            "address": formData.get('address') ? formData.get('address') : null
        };
        const comment = formData.get('comment') ? formData.get('comment') : 'Без комментариев'
        placeOrder(customer, products, comment);
        var form = document.getElementById("cartForm");
        form.reset();
        setCart([]);
        setisOrderAdded(true);
    }

    return (
        <div className='cart'>
            <h2 className='cartTitle'>Корзина</h2>
            {
                cart.length === 0 && <p className='cartEmpty'>Корзина пуста</p>
            }
            {

                (isOrderAdded) && <div className='modal-wrapper'>
                    <div className=' popupOrder'>
                        <p className='cartOrderTitle'>Спасибо!</p>
                        <p className='cartOrderText'>Ваш заказ успешно оформлен. Мы свяжемся с вами в ближайшее время.</p>
                        <Link to={`/`}>
                            <button className='cartButton'>Вернуться в магазин</button>
                        </Link>
                    </div>
                </div>
            }

            {cart.map(item => (
                <div key={item.metadata.uid} className='cartItem'>
                    <img className='cartItemImg' src={item.previewImage} alt={item.name} />
                    <div className='cartItemInfo'>
                        <p>{item.name}</p>
                    </div>

                    <div className='cartItemPrice'>
                        <p>Цена: {item.price} р.</p>
                    </div>
                    <div className='cartItemCount'>
                        <button onClick={() => handleMinusFromCart(item)}>-</button>
                        <p>{item.quantity}</p>
                        <button onClick={() => handlePlusToCart(item)}>+</button>
                    </div>
                </div>
            ))}
            {
                cart.length > 0 && <div>
                    <p className='cartTotal'>Итого: {cart.reduce((acc, item) => acc + item.price * item.quantity, 0)} р.</p>
                    <form onSubmit={handleSubmitOrder} autoComplete="false" className='cartForm' id='cartForm'>
                        <div className='cartInputBox'>
                            <label className=''>Имя или название организации <span className="required">*</span></label>
                            <input required className='cartInput' name='fullname' type='text' placeholder='Иванов Иван' minLength="4" autoComplete="false" />
                        </div>

                        <div className='cartInputBox'>
                            <label className=''>Телефон <span className="required">*</span></label>
                            <input required className='cartInput' name='phone' type='tel' placeholder='+7(000)000-00-00' autoComplete="false" />
                        </div>

                        <div className='cartInputBox'>
                            <label className=''>Email <span className="required">*</span></label>
                            <input required className='cartInput' name='email' type='email' placeholder='forExamle@mail.ru' autoComplete="false" />
                        </div>

                        <div className='cartInputBox'>
                            <label className=''>Адрес доставки, если вы собираетесь заказать услугу доставки</label>
                            <input className='cartInput' name='address' type='text' placeholder='г.Москва, ул. Южная' autoComplete="false" />
                        </div>

                        <div className='cartInputBox'>
                            <label className=''>Комментарий</label>
                            <textarea className='cartInput' name='comment' type='text' autoComplete="false" />
                        </div>

                        <button className='cartButton' type='submit'>Сделать заказ</button>
                    </form>
                    <p>
                        <br />
                        <strong>УВЕДОМЛЕНИЕ:</strong> нажимая кнопку «Сделать заказ», Вы принимаете <a href="/PrivacyPolicy" style={{color:'orange'}} target="_blank"><u>соглашение о конфиденциальности</u></a> и соглашаетесь с обработкой персональных данных.
                    </p>
                </div>
            }
        </div>
    )
}
