import './Header.css';
import React from "react";
import { Link } from "react-router-dom";
import iaa from '../../images/bucket_logo.svg'

export default function Header({ cart }) {
    return (
        <header id="header">
            <div className="header">

                <div className="header__link-logo_box">
                    <Link className="header__link-logo link" to="/" />
                    <a className='header__number' href="tel:+7 (495) 746-81-40">&#9990; 8 (495) 746-81-40</a>
                </div>

                <div className="header__links">
                    <Link to="/About" className="link">О Компании</Link>
                    <Link to="/Documentation" className="link">Документация</Link>
                    <Link to="/Contacts" className="link">Контакты</Link>
                    <Link to="/Technologies" className="link">Технология</Link>
                    <Link to="/Partners" className="link">Стать партнером</Link>
                </div>
            </div>
            
            <Link to='/Cart'>
                <button className="header__cart" href='/Cart'>
                    <img className='header__cart-logo' src={iaa} alt="Cart"></img>
                    {!(cart.length === 0) && <div className="header__cart__num" id="cart_num">{cart.length}</div>}
                </button>
            </Link>
        </header>
    )
}