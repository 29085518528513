import './Footer.css';
import { Link } from "react-router-dom";

export default function Footer() {
    return (
        <footer>
            <section id="footer">
                <div className="footer">
                    <div className='footer__logo'>
                        <Link className="footer__link-logo" to="/" />
                        &#169; 2023 Company Inc.
                    </div>
                    <div className='footer__links'>
                        <Link className="footer__link link" to="/PrivacyPolicy">Политика конфиденциальности</Link>
                        <Link className="footer__link link" to="/About">О нас</Link>
                    </div>
                    <div className='footer__contacts'>
                        <a className='footer__number' href="tel:+7 (495) 746-81-40">&#9990; 8 (495) 746-81-40</a>

                        <a className='footer__mail' href="mailto:746-81-40@mail.ru">746-81-40@mail.ru</a>
                    </div>
                    <div className='footer__contacts_address'>
                        <p className='footer__point'>&#128205;</p>
                        <div>
                            <b> Адрес</b>
                            <p> г. Мытищи, <br/> ул. Силикатная, 36, <br/> офис 317</p>
                        </div>
                    </div>
                </div>
            </section>
        </footer>
    )
}